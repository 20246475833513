/* eslint-disable complexity */
/* eslint-disable react/jsx-no-literals */

import React, { useState, useEffect } from 'react'
import { node, func } from 'prop-types'
import { FieldFormWrapper } from 'components/Wrappers/wrappers.style'
import { inputStyle } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'
import { ZipcodeInputField } from 'components/MaskedInputField'
import { DetailsClientSelectField } from 'components/InputField/DetailsClientSelectField'
import { mapDefaultOptionsBuilder } from 'utils/builders'
import { useLocale } from 'hooks/useLocale'
import { FormClientInfomationGeralStyle } from './form.style'

const propTypes = {
  values: node.isRequired,
  errors: node.isRequired,
  touched: node.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
}

const FormClientInfomationAdress = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  const { getCities, getStates } = useLocale()
  const [states, setStates] = useState([])
  const [citiesByState, setCitiesByState] = useState({})

  useEffect(() => {
    getStates().then(data => {
      const sortedStates = data
        .sort((a, b) => (a.sigla > b.sigla ? 1 : -1))
        .map(({ sigla }) => sigla)

      setStates(() => sortedStates)

      Promise.all(sortedStates.map(state => getCities({ state }))).then(
        citiesResponse => {
          setCitiesByState(() =>
            sortedStates.reduce((acc, state, index) => {
              acc[state] = citiesResponse[index].map(({ nome }) => nome)
              return acc
            }, {}),
          )
        },
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormClientInfomationGeralStyle>
      <FieldFormWrapper>
        <InputField
          id="streetAddress"
          name="streetAddress"
          value={values.streetAddress}
          label="Rua"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.streetAddress && errors.streetAddress ? 'danger' : 'default'
          }
          helperText={touched.streetAddress && errors.streetAddress}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="streetNumber"
          name="streetNumber"
          value={values.streetNumber}
          label="Número"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.streetNumber && errors.streetNumber ? 'danger' : 'default'
          }
          helperText={touched.streetNumber && errors.streetNumber}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="complement"
          name="complement"
          value={values.complement}
          label="Complemento"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.complement && errors.complement ? 'danger' : 'default'
          }
          helperText={touched.complement && errors.complement}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="neighborhood"
          name="neighborhood"
          value={values.neighborhood}
          label="Bairro"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.neighborhood && errors.neighborhood ? 'danger' : 'default'
          }
          helperText={touched.neighborhood && errors.neighborhood}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <ZipcodeInputField
          id="zipcode"
          name="zipcode"
          value={values.zipcode}
          label="CEP"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            (touched.zipcode || values.zipcode) && errors.zipcode
              ? 'danger'
              : 'default'
          }
          helperText={(touched.zipcode || values.zipcode) && errors.zipcode}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="state"
          name="state"
          value={values.state}
          label="Estado"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={states.map(mapDefaultOptionsBuilder({}))}
          variant={touched.state && errors.state ? 'danger' : 'default'}
          helperText={touched.state && errors.state}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="city"
          name="city"
          value={values.city}
          label="Cidade"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={
            citiesByState[values.state] == null
              ? []
              : citiesByState[values.state].map(mapDefaultOptionsBuilder({}))
          }
          variant={touched.city && errors.city ? 'danger' : 'default'}
          helperText={touched.city && errors.city}
        />
      </FieldFormWrapper>
    </FormClientInfomationGeralStyle>
  )
}

FormClientInfomationAdress.propTypes = propTypes

export { FormClientInfomationAdress }
