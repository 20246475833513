import React from 'react'

import { Button } from '@creditas/button'
import { buttonSimulateStyle } from './button.style'

// eslint-disable-next-line react/prop-types
const ButtonSimulate = ({ children, isLoading, disabled = false }) => (
  <Button
    fullWidth
    variant="tertiary"
    css={buttonSimulateStyle}
    isLoading={isLoading}
    disabled={disabled}
  >
    {children}
  </Button>
)
export { ButtonSimulate }
