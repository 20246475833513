import axios from 'axios'
import HttpStatus from 'http-status-codes'
import { unmaskCpf, unmaskCurrency } from 'utils/maskRemover'

export function useLoanSimulation() {
  const axiosInstance = axios.create({
    baseURL: process.env.PENSION_BACKED_CREDIT_GATEWAY,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: false,
  })

  async function getMaximumRequestableLoanAmount({
    customerToken = '',
    document = '',
  }) {
    const response = await axiosInstance.request({
      method: 'GET',
      url: `/v0/${unmaskCpf(
        document,
      )}/simulation/maximum-requestable-loan-amount`,
      headers: {
        Authorization: customerToken,
      },
    })

    return response.status == HttpStatus.OK ? response.data : null
  }

  async function getLoanSimulation({
    customerToken = '',
    document = '',
    requestedLoanAmount = 0.0,
    term = 12,
  }) {
    const response = await axiosInstance.request({
      method: 'POST',
      url: `/v0/${unmaskCpf(document)}/simulation`,
      headers: {
        Authorization: customerToken,
      },
      data: {
        requestedLoanAmount: unmaskCurrency(requestedLoanAmount),
        term,
      },
    })

    return response.status == HttpStatus.OK ? response.data : null
  }

  async function createProposal({
    customerToken = '',
    customerId = '',
    requestedLoanAmount = 0.0,
    term = 12,
  }) {
    const response = await axiosInstance.request({
      method: 'POST',
      url: `/v0/proposal/${customerId}`,
      headers: {
        Authorization: customerToken,
      },
      data: {
        requestedLoanAmount: unmaskCurrency(requestedLoanAmount),
        term,
      },
    })

    return response.status == HttpStatus.OK ? response.data : null
  }

  return { getMaximumRequestableLoanAmount, getLoanSimulation, createProposal }
}
