import React from 'react'
import { navigate } from 'gatsby'
import { string } from 'prop-types'

import { Button } from '@creditas/button'
import { buttonSignOnStyle } from './button.style'

// eslint-disable-next-line react/prop-types
const propTypes = {
  navigateTo: string.isRequired,
  children: string,
}

const ButtonSignOn = ({ children, navigateTo }) => (
  <Button
    fullWidth
    variant="tertiary"
    onClick={() => navigate(navigateTo)}
    css={buttonSignOnStyle}
  >
    {children}
  </Button>
)
export { ButtonSignOn }
ButtonSignOn.propTypes = propTypes
