import { styled, css } from '@creditas/stylitas'

export const FormLoginInputStyle = styled.div`
  background: linear-gradient(to right, #1cc 0%, #2e8 100%);
  border: 1px solid linear-gradient(to right, #1cc 0%, #2e8 100%);
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  width: 450px;
  top: 100%;
  left: 30%;
  margin-top: 70px;
  margin-left: 20%;
  margin-right: 6%;
  :focus {
    border-color: #85b7d9;
  }
`

export const inputLoginIndexStyle = css`
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  border: none;
  border-radius: 0;
  padding: 5px 5px;
  color: rgba(74, 74, 74, 0.54);
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  text-align: left;
  width: 98%;
  outline: none;
`

export const FormLoginLogoStyle = styled.div`
  margin-top: 50px;
  padding: 6px;
  margin-left: 30%;
  display: inline-block;
`

export const FormLoginButtonStyle = styled.div`
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.21428571em;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background-color 0.1s ease, box-shadow 0.1s eaase;
  margin: 8px;
  margin-top: 20px;
`

export const FormLoginStyle = styled.div`
  background: linear-gradient(to right, #1cc 0%, #2e8 100%);
  border: 1px solid #ccc;
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  display: block;
  flex-direction: column;
  padding: 16px;
  min-width: 740px;
  width: 40vw;
  height: 420px;
  margin-left: 30%;
  margin-right: auto;
  margin-top: 3%;
`

export const FormClientInfomationGeralStyle = styled.form`
  display: block;
  position: relative;
  padding: 16px;
  align-items: baseline;
  box-sizing: border-box;
  justify-content: center;
  margin-left: 100px;
  margin-right: 100px;
`

export const FormSimulationTaxasSimpleStyle = styled.div`
  display: inline-flex;
  position: relative;
  padding: 16px;
  align-items: baseline;
  box-sizing: border-box;
  justify-content: center;
  margin-left: 100px;
  margin-right: 100px;
`
