/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback } from 'react'
import { Layout } from 'components/Layout'
import { Container } from '@creditas/layout'
import { LoginForm } from 'src/components/Form'
import { useConsultant } from 'hooks/useConsultant'

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [hasAccessToken, setHasAccressToken] = useState(false)
  const { getAuthentication, setLocalStorageInfo } = useConsultant()

  const sendRequest = useCallback(
    async (username, password) => {
      setIsLoading(true)
      setShowError(false)
      const response = await getAuthentication({ username, password })
      if (response) {
        setLocalStorageInfo(response)
        setIsLoading(false)
        setShowError(false)
        setHasAccressToken(true)
      } else {
        setIsLoading(false)
        setShowError(true)
        setHasAccressToken(false)
      }
    },
    [isLoading, showError, hasAccessToken],
  ) // update the callback if the state changes

  return (
    <Layout>
      <Container>
        <LoginForm
          login={sendRequest}
          showError={showError}
          isLoading={isLoading}
          hasAccessToken={hasAccessToken}
        />
      </Container>
    </Layout>
  )
}

export default Login
