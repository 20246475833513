import * as yup from 'yup'
import { REQUIRED_FIELD_MESSAGE } from './constants'

const documentSchema = yup
  .string()
  .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/i, 'CPF inválido.')
  .required(REQUIRED_FIELD_MESSAGE)

const customerTokenSchema = yup
  .string()
  .matches(/^\d{6}$/i, 'Código do cliente deve conter 6 dígitos.')
  .required(REQUIRED_FIELD_MESSAGE)

const onboardingSchema = yup.object().shape({
  document: documentSchema,
  customerToken: customerTokenSchema,
})

export { onboardingSchema }
