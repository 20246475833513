import { css } from '@creditas/stylitas'

export const defaultButtonStyle = css`
  background-color: #2e8;
  width: 10%;
  height: 40px;
  minwidth: 1px;
  :hover {
  }

  :focus-within {
  }
`

export const buttonIndexClientInformationStyle = css`
  cursor: default !important;
  background-color: white;
  color: rgba(74, 74, 74, 0.54);
  height: 48px;
  min-width: 1px;
  width: 10%;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  bottom: 15px;
  border-radius: inherit;
  border-bottom: 1px solid rgba(74, 74, 74, 0.12);
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;
  padding: 10px 54px;
  :enabled {
    color: #005599;
    border: none;
    border-bottom: 2px solid #2e8;
  }
`

export const buttonClientInformationStyle = css`
  cursor: default !important;
  background-color: white;
  color: rgba(74, 74, 74, 0.54);
  height: 48px;
  min-width: 1px;
  width: 10%;
  justify-content: center;
  padding: 0 24px;
  align-items: center;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  top: 15px;
  left: 35%;
  border-radius: inherit;
  border-bottom: 1px solid rgba(74, 74, 74, 0.12);
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;
  :enabled {
    color: #005599;
    border: none;
    border-bottom: 2px solid #2e8;
  }
`

export const buttonSaveStyle = css`
  color: #4a4a4a;
  background-color: #ccff22;
  width: 10%;
  min-width: 1px;
  line-height: 36px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: inline-block;
  border-radius: 4;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  left: 1500px;
  bottom: 80px;
  border-radius: 4px;
  text-transform: uppercase;
  :hover {
    background-color: #ccff22;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1) box-shadow
      280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`

export const buttonIndexStyle = css`
  color: #4a4a4a;
  background-color: #ccff22;
  width: 150%;
  min-width: 1px;
  line-height: 36px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: inline-block;
  border-radius: 4;
  vertical-align: baseline;
  margin: 10px;
  top: 10%;
  left: 12%;
  padding: 0 30px;
  white-space: nowrap;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  :hover {
    background-color: #ccff22;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1) box-shadow
      280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  :focus-within {
  }
`

export const buttonSimulateStyle = css`
  color: #4a4a4a;
  background-color: #ccff22;
  width: 39%;
  min-width: 1px;
  line-height: 36px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: inline-block;
  border-radius: 4;
  vertical-align: baseline;
  margin: 0;
  bottom: 10%;
  left: 25%;
  padding: 0 10px;
  white-space: nowrap;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  :hover {
    background-color: #ccff22;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1) box-shadow
      280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  :focus-within {
  }
`

export const ButtonSaveProposalStyle = css`
  color: #4a4a4a;
  background-color: #ccff22;
  width: 60%;
  min-width: 1px;
  line-height: 36px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: inline-block;
  border-radius: 4;
  vertical-align: baseline;
  margin: 0;
  top: 10px;
  left: 20%;
  padding: 0 10px;
  white-space: nowrap;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  :hover {
    background-color: #ccff22;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1) box-shadow
      280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  :focus-within {
  }
`
export const buttonSignOnStyle = css`
  color: white;
  background-color: #3377bb;
  width: 25%;
  min-width: 1px;
  line-height: 36px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: inline-block;
  border-radius: 4;
  vertical-align: baseline;
  margin: 0;
  top: 10%;
  left: 35%;
  padding: 0 16px;
  white-space: nowrap;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  :hover {
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1) box-shadow
      280ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #005599;
  }

  :focus-within {
  }
`
