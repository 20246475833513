import React from 'react'
import { node, func } from 'prop-types'
import { FieldFormWrapper } from 'components/Wrappers/wrappers.style'
import { inputStyle } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'
import { DetailsClientSelectField } from 'components/InputField'
import { mapTextValueOptionsBuilder } from 'utils/builders'
import {
  ACCEPTABLE_BANKS,
  ACCEPTABLE_ACCOUNT_TYPES,
} from 'constants/customerInformation'
import { FormClientInfomationGeralStyle } from './form.style'

const propTypes = {
  values: node.isRequired,
  errors: node.isRequired,
  touched: node.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
}

const FormClientInfomationBank = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  const bankList = ACCEPTABLE_BANKS.map(bank => {
    let bankId = bank[0]
    while (bankId.length < 3) bankId = `0${bankId}`
    return {
      value: bank[0],
      text: `${bankId} - ${bank[1]}`,
      main: !!bank[2],
    }
  })
    .sort((a, b) => {
      if (isNaN(a.value)) return 1
      return Number(a.value) > Number(b.value) ? 1 : -1
    })
    .sort((a, b) => (a.main && !b.main ? -1 : 1))

  return (
    <FormClientInfomationGeralStyle>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="bankId"
          name="bankId"
          value={values.bankId}
          label="Banco"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={touched.bankId && errors.bankId ? 'danger' : 'default'}
          helperText={touched.bankId && errors.bankId}
          options={bankList}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="agencyNumber"
          name="agencyNumber"
          value={values.agencyNumber}
          label="Agência"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.agencyNumber && errors.agencyNumber ? 'danger' : 'default'
          }
          helperText={touched.agencyNumber && errors.agencyNumber}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="accountNumber"
          name="accountNumber"
          value={values.accountNumber}
          label="Conta (Com dígito, se houver)"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.accountNumber && errors.accountNumber ? 'danger' : 'default'
          }
          helperText={touched.accountNumber && errors.accountNumber}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DetailsClientSelectField
          disabled
          id="accountType"
          name="accountType"
          value={values.accountType}
          label="Tipo de conta"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={ACCEPTABLE_ACCOUNT_TYPES.map(mapTextValueOptionsBuilder())}
        />
      </FieldFormWrapper>
    </FormClientInfomationGeralStyle>
  )
}

FormClientInfomationBank.propTypes = propTypes

export { FormClientInfomationBank }
