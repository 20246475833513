/* eslint-disable react/jsx-no-literals */

import React, { useContext, useEffect } from 'react'
import { ApplicationContext } from 'src/context/Application'
import { useFormik } from 'formik'
import { navigate } from 'gatsby'
import { ButtonIndex, ButtonIndexClientInformation } from 'components/Button'
import {
  FormIndexWrapper,
  FormWrapper,
  WizardWrapper,
  WizardLabelWrapper,
} from 'components/Wrappers/wrappers.style'
import { inputIndexStyle } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'
import { DocumentInputField } from 'components/MaskedInputField'
import { H2IndexStyle } from 'components/Title/Title.style'
import { Steps } from 'components/Wizard'
import { fieldValidation, onboardingSchema } from 'schemas'
import { useToaster } from '@creditas/toaster'
import { useLoanSimulation } from 'hooks/useLoanSimuation'
import { useConsultant } from 'hooks/useConsultant'
import { useCustomer } from 'hooks/useCustomer'

const FormIndex = () => {
  const { push } = useToaster()
  const { getMaximumRequestableLoanAmount } = useLoanSimulation()
  const { getCustomerInformation } = useCustomer()
  const { state, dispatcher } = useContext(ApplicationContext)
  const {
    showTokenInfo,
    refreshTokenAuthentication,
    removeLocalStorageInfo,
    setLocalStorageInfo,
  } = useConsultant()

  async function onSubmit(values) {
    let maximumRequestableLoanAmountData, customerData, refreshedToken

    const tokenInfo = await showTokenInfo()

    if (!tokenInfo) {
      refreshedToken = await refreshTokenAuthentication(
        window.localStorage.getItem('creditas-affiliates-state-refresh-token'),
      )
      if (!refreshedToken) {
        removeLocalStorageInfo()
        push('Token de acesso expirado', {
          level: 'danger',
          onClose: () => navigate('/'),
        })
        throw new Error('Error of authentication')
      }
      setLocalStorageInfo()
    }

    if (state.maximumRequestableLoanAmount == '') {
      maximumRequestableLoanAmountData = await getMaximumRequestableLoanAmount(
        values,
      )

      if (maximumRequestableLoanAmountData == null) {
        push('CPF não encontrado ou código do cliente inválido', {
          level: 'danger',
        })
        throw new Error('Error getting customer information')
      } else {
        dispatcher({
          type: 'authenticateByPartner',
          payload: { isAuthenticatedByPartner: true },
        })

        dispatcher({ type: 'simulationReset', payload: {} })

        await dispatcher({
          type: 'maximumRequestableLoanAmount',
          payload: maximumRequestableLoanAmountData,
        })
      }
    } else {
      maximumRequestableLoanAmountData = state.maximumRequestableLoanAmount
    }

    if (state.fullname == '') {
      customerData = await getCustomerInformation(values)

      if (customerData == null) {
        push('CPF não encontrado ou código do cliente inválido', {
          level: 'danger',
        })
        throw new Error('Error getting customer information')
      } else {
        await dispatcher({
          type: 'customer',
          payload: { ...values, ...customerData },
        })
      }
    } else {
      customerData = state.fullname
    }

    if (maximumRequestableLoanAmountData != null && customerData != null) {
      navigate('/simulacao-comercial')
    }
  }

  useEffect(() => {
    dispatcher({ type: 'reset', payload: {} })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formikConfig = {
    initialValues: {
      document: '',
      customerToken: '',
    },
    onSubmit,
    validationSchema: onboardingSchema,
    isInitialValid: false,
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldError,
    isValid,
  } = useFormik(formikConfig)

  async function onBlur(event) {
    event.persist()

    const fieldName = event.target.name
    const fieldValue = event.target.value

    await fieldValidation({
      setFieldTouched,
      setFieldError,
      schema: onboardingSchema,
      field: fieldName,
      value: fieldValue,
    })

    return handleBlur(event)
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <div>
        <H2IndexStyle>
          <h2>Consulta</h2>
        </H2IndexStyle>
      </div>
      <WizardWrapper>
        <Steps current={1} />
        <WizardLabelWrapper>
          <ButtonIndexClientInformation>consulta</ButtonIndexClientInformation>
          <ButtonIndexClientInformation disabled>
            simulação
          </ButtonIndexClientInformation>
          <ButtonIndexClientInformation disabled>
            visão geral
          </ButtonIndexClientInformation>
        </WizardLabelWrapper>
      </WizardWrapper>
      <FormIndexWrapper>
        <DocumentInputField
          css={inputIndexStyle}
          id="document"
          name="document"
          value={values.document}
          label="CPF"
          type="text"
          onChange={handleChange}
          onBlur={onBlur}
          variant={touched.document && errors.document ? 'danger' : 'default'}
          helperText={touched.document && errors.document}
        />
        <InputField
          css={inputIndexStyle}
          id="customerToken"
          name="customerToken"
          value={values.customerToken}
          label="Token do Cliente"
          type="text"
          onChange={handleChange}
          onBlur={onBlur}
          variant={
            touched.customerToken && errors.customerToken ? 'danger' : 'default'
          }
          helperText={touched.customerToken && errors.customerToken}
        />
        <ButtonIndex
          isLoading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          Nova Simulação
        </ButtonIndex>
      </FormIndexWrapper>
    </FormWrapper>
  )
}

export { FormIndex }
