export function mapDefaultOptionsBuilder({ additionalText = '' }) {
  return value => ({
    value,
    text: `${value} ${additionalText}`.trim(),
  })
}

export function mapTextValueOptionsBuilder() {
  return ([value, text]) => ({
    value,
    text,
  })
}
