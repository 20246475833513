/* eslint-disable react/jsx-no-literals */

import React, { useContext } from 'react'
import { useFormik } from 'formik'
import { ApplicationContext } from 'context/Application'
import { AmountInputField } from 'components/MaskedInputField'
import { ButtonSimulate } from 'components/Button/ButtonSimulate'
import { SimulationFieldFormWrapper } from 'components/Wrappers/wrappers.style'
import { inputStyle } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'
import { DetailsClientSelectField } from 'components/InputField'
import { useLoanSimulation } from 'hooks/useLoanSimuation'
import { fieldValidation, useSimulationSchema } from 'schemas'
import { toCurrency } from 'utils/formatters'
import { mapDefaultOptionsBuilder } from 'utils/builders'
import {
  ACCEPTABLE_TERMS,
  MAXIMUM_REQUESTABLE_LOAN_AMOUNT,
} from 'constants/simulation'
import { FormClientInfomationGeralStyle } from './form.style'

const FormSimulationSimple = () => {
  const { getLoanSimulation } = useLoanSimulation()
  const { state, dispatcher } = useContext(ApplicationContext)
  const simulationSchema = useSimulationSchema(
    state.maximumRequestableLoanAmount,
  )

  async function onSubmit(values) {
    dispatcher({
      type: 'simulationConditionsUpdate',
      payload: values,
    })

    const data = await getLoanSimulation({
      customerToken: state.customerToken,
      document: state.document,
      ...values,
    })

    if (data == null)
      throw new Error('Error getting maximum requestable loan amount')
    else {
      dispatcher({
        type: 'simulationUpdate',
        payload: data,
      })
    }
  }

  const formikConfig = {
    initialValues: {
      requestedLoanAmount: 'R$ 1.000,00',
      term: 12,
    },
    onSubmit,
    validationSchema: simulationSchema,
    isInitialValid: true,
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldTouched,
    setFieldError,
    isValid,
  } = useFormik(formikConfig)

  async function onBlur(event) {
    event.persist()

    const fieldName = event.target.name
    const fieldValue = event.target.value

    await fieldValidation({
      setFieldTouched,
      setFieldError,
      schema: simulationSchema,
      field: fieldName,
      value: fieldValue,
    })

    return handleBlur(event)
  }

  return (
    <FormClientInfomationGeralStyle onSubmit={handleSubmit}>
      <div>
        <SimulationFieldFormWrapper>
          <InputField
            disabled
            value={`R$ ${toCurrency({
              number: state.maximumRequestableLoanAmount,
            })}`}
            variant="default"
            label="Valor máximo"
            css={inputStyle}
            type="text"
          />
        </SimulationFieldFormWrapper>
      </div>
      <div>
        <SimulationFieldFormWrapper>
          <AmountInputField
            valueLimit={MAXIMUM_REQUESTABLE_LOAN_AMOUNT}
            css={inputStyle}
            id="requestedLoanAmount"
            name="requestedLoanAmount"
            value={values.requestedLoanAmount}
            label="Valor à ser Simulado *"
            type="text"
            onChange={handleChange}
            onBlur={onBlur}
            variant={
              touched.requestedLoanAmount && errors.requestedLoanAmount
                ? 'danger'
                : 'default'
            }
            helperText={
              touched.requestedLoanAmount && errors.requestedLoanAmount
            }
          />
        </SimulationFieldFormWrapper>
      </div>
      <div>
        <SimulationFieldFormWrapper>
          <DetailsClientSelectField
            options={ACCEPTABLE_TERMS.map(
              mapDefaultOptionsBuilder({ additionalText: 'parcelas' }),
            )}
            id="term"
            name="term"
            value={values.term}
            label="Número de Parcelas"
            type="text"
            onChange={handleChange}
            onBlur={onBlur}
            variant={touched.term && errors.term ? 'danger' : 'default'}
            helperText={touched.term && errors.term}
          />
        </SimulationFieldFormWrapper>
      </div>
      <SimulationFieldFormWrapper>
        <div>
          <ButtonSimulate
            isLoading={isSubmitting}
            disabled={isSubmitting || !isValid}
          >
            Simular
          </ButtonSimulate>
        </div>
      </SimulationFieldFormWrapper>
    </FormClientInfomationGeralStyle>
  )
}

export { FormSimulationSimple }
