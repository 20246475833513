/* eslint-disable react/jsx-no-literals */

import React, { useState } from 'react'
import { string, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { InputField } from '@creditas/input'

const propTypes = {
  initialClientCPF: string,
  onSubmit: func.isRequired,
}

const defaultProps = {
  initialClientCPF: '',
}

const Form = ({ initialClientCPF = '', onSubmit }) => {
  const { t } = useTranslation()
  const [clientCPF, setClient] = useState(initialClientCPF)

  function handleChange(e) {
    setClient(e.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()
    onSubmit(clientCPF)
  }

  return (
    <form onSubmit={handleSubmit}>
      <InputField
        search
        placeholder={t('customer:placeholders:search')}
        onChange={handleChange}
        onBlur={handleChange}
        value={clientCPF}
        InputProps={{
          'data-testid': 'input-client-cpf',
        }}
      />
    </form>
  )
}

Form.propTypes = propTypes
Form.defaultProps = defaultProps
Form.displayName = 'Form'

export { Form }
