/* eslint-disable react/prop-types */
import React from 'react'

import { Button } from '@creditas/button'
import { any } from 'prop-types'
import {
  buttonClientInformationStyle,
  buttonIndexStyle,
  defaultButtonStyle,
  buttonIndexClientInformationStyle,
} from './button.style'

const propTypes = {
  children: any,
}

const ButtonDefault = ({ children }) => (
  <Button fullWidth variant="tertiary" css={defaultButtonStyle}>
    {children}
  </Button>
)
export { ButtonDefault }

const ButtonMenu = ({ children }) => (
  <Button fullWidth variant="tertiary">
    {children}
  </Button>
)
export { ButtonMenu }

const ButtonClientInformation = ({ children, disabled = false }) => (
  <Button
    disabled={disabled}
    fullWidth
    variant="tertiary"
    css={buttonClientInformationStyle}
  >
    {children}
  </Button>
)
export { ButtonClientInformation }

const ButtonIndexClientInformation = ({ children, disabled = false }) => (
  <Button
    disabled={disabled}
    fullWidth
    variant="tertiary"
    css={buttonIndexClientInformationStyle}
  >
    {children}
  </Button>
)
export { ButtonIndexClientInformation }

const ButtonIndex = ({ children, isLoading, disabled = false }) => (
  <Button
    fullWidth
    variant="tertiary"
    css={buttonIndexStyle}
    isLoading={isLoading}
    disabled={disabled}
  >
    {children}
  </Button>
)
export { ButtonIndex }

ButtonIndex.propTypes = propTypes
