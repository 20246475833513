import { styled, css } from '@creditas/stylitas'

const defaultWrapperStyle = css`
  position: relative;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`

export const TableWrapper = styled.div`
  ${defaultWrapperStyle}
  display: block;
  padding: 16px;
  color: #4a4a4a;
  min-width: 1715px;
}
`

export const FormWrapper = styled.form`
  ${defaultWrapperStyle}
  width: 40vw;
  height: 420px;
  min-height: 320px;
  min-width: 740px;
  display: block;
  flex-direction: column;
  margin-top: 3%;
  margin-left: 30%;
  padding: 16px;
`

export const FormIndexWrapper = styled.div`
  margin-top: 5.0625em;
  margin: 0.0625em 150px;
  padding: 5px 25px;
  display: block;
  width: 30%;
  height: 70%;
`

export const FieldFormWrapper = styled.div`
  width: 15%;
  flex: auto;
  display: inline-flex;
  min-height: 1px;
  flex-direction: column;
  margin-top: 5%;
  padding: 15px;
  margin: 10px;
`

const defaultFieldWrapperStyle = css`
  width: 90px;
  color: #111515;
  :after {
  }
  .legend {
    color: #111515;
  }
`

export const FieldFormSimulationWrapper = styled.div`
  ${defaultFieldWrapperStyle}
  flex: auto;
  display: inline-flex;
  flex-direction: column;
  padding: 0px 70px 0px 20px;
  width: 70%;
  margin-top: 5%;
  margin: 0px;
  color: #111515;
  font-weight: 500;
  font-size: 14px;
  margin-left: -130px;
`
// export const FieldFormSimulationComparWrapper = styled.div`
//   ${defaultFieldWrapperStyle}
//   flex: auto;
//   display: inline-flex;
//   min-height: 1px;
//   flex-direction: column;
//   padding: 0px;
//   width: 50%;
//   margin-top: 5%;
//   margin: 0px;
//   color: #111515;
//   font-weight: 500;
//   font-size: 15px;
//   background-color: red;
// `

export const FormSimulationWrapper = styled.div`
  ${defaultWrapperStyle}
  top: 2.0625em;
  left: 320px;
  padding: 3px 3px;
  display: block;
  width: 30%;
`

export const SimulationFieldFormWrapper = styled.div`
  ${defaultFieldWrapperStyle}
  flex: auto;
  display: inline-flex;
  min-height: 1px;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  min-width: 282px;
  margin-top: 5%;
  margin: 0px;
  color: #111515;
  font-weight: 500;
  font-size: 15px;
`

const defaultInformationSimulationWrapperStyle = css`
  position: relative;
  border-radius: 4px;
  min-width: 123px;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px white, 0px 1px 1px 0px white,
    0px 1px 3px 0px white;
`
export const FormInformationSimulationWrapper = styled.div`
  ${defaultInformationSimulationWrapperStyle}
  bottom: 10.0625em;
  left: 900px;
  min-width: 123px;
  padding: 3px 3px;
  display: block;
  width: 30%;
`

export const WizardLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const WizardWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
`
