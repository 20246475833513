import React from 'react'
import { node } from 'prop-types'
import { slashDateMask } from 'masks/date'
import { BaseMaskedInputField } from './BaseMaskedInputField'

const propTypes = {
  props: node,
}

const DateInputField = ({ ...props }) => (
  <BaseMaskedInputField
    mask={slashDateMask}
    data-testid="date-field"
    {...props}
  />
)

DateInputField.propTypes = propTypes

export { DateInputField }
