import axios from 'axios'
import HttpStatus from 'http-status-codes'
import { unmaskCpf, unmaskCurrency } from 'utils/maskRemover'
import { toIsoDate } from 'utils/formatters'

export function useCustomer() {
  const axiosInstance = axios.create({
    baseURL: process.env.PENSION_BACKED_CREDIT_GATEWAY,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: false,
  })

  async function getCustomerInformation({ customerToken = '', document = '' }) {
    const response = await axiosInstance.request({
      method: 'GET',
      url: `/v0/customer/${unmaskCpf(document)}/icatu`,
      headers: {
        Authorization: customerToken,
      },
    })

    return response.status == HttpStatus.OK ? response.data : null
  }

  async function createCustomer({
    customerToken = '',
    fullname = '',
    document = '',
    birthDate = '',
    email = '',
    phoneNumber = '',
    maritalStatus = '',
    motherName = '',
    rgNumber = '',
    documentType = '',
    documentNumber = '',
    documentIssuingDate = '',
    documentIssuingBody = '',
    profession = '',
    birthCity = '',
    birthState = '',
    birthCountry = '',
    income = '',
    patrimony = '',
    politicallyExposed = '',
    streetAddress = '',
    streetNumber = '',
    complement = null,
    neighborhood = '',
    zipcode = '',
    city = '',
    state = '',
    bankId = '',
    agencyNumber = '',
    accountNumber = '',
    accountType = '',
  }) {
    const response = await axiosInstance.request({
      method: 'POST',
      url: `/v0/customer/`,
      headers: {
        Authorization: customerToken,
      },
      data: {
        person: {
          fullname,
          document,
          birthDate,
          email,
          phoneNumber,
          maritalStatus,
          motherName,
          rgNumber,
          documentType,
          documentNumber,
          documentIssuingDate: toIsoDate({ date: documentIssuingDate }),
          documentIssuingBody,
          profession,
          birthCity,
          birthState,
          birthCountry,
          income: unmaskCurrency(income),
          patrimony,
          politicallyExposed,
        },
        address: {
          streetAddress,
          streetNumber,
          complement,
          neighborhood,
          zipcode: zipcode.replace(/-/g, ''),
          city,
          state,
        },
        bankAccount: {
          bankId,
          agencyNumber,
          accountNumber,
          accountType,
        },
      },
    })

    return response.status == HttpStatus.CREATED ? response.data : null
  }

  async function updateCustomer({
    customerToken = '',
    fullname = '',
    document = '',
    birthDate = '',
    email = '',
    phoneNumber = '',
    maritalStatus = '',
    motherName = '',
    rgNumber = '',
    documentType = '',
    documentNumber = '',
    documentIssuingDate = '',
    documentIssuingBody = '',
    profession = '',
    birthCity = '',
    birthState = '',
    birthCountry = '',
    income = '',
    patrimony = '',
    politicallyExposed = '',
    streetAddress = '',
    streetNumber = '',
    complement = null,
    neighborhood = '',
    zipcode = '',
    city = '',
    state = '',
    bankId = '',
    agencyNumber = '',
    accountNumber = '',
    accountType = '',
  }) {
    const response = await axiosInstance.request({
      method: 'POST',
      url: `/v0/customer/${unmaskCpf(document)}/update`,
      headers: {
        Authorization: customerToken,
      },
      data: {
        person: {
          fullname,
          document,
          birthDate,
          email,
          phoneNumber,
          maritalStatus,
          motherName,
          rgNumber,
          documentType,
          documentNumber,
          documentIssuingDate: toIsoDate({ date: documentIssuingDate }),
          documentIssuingBody,
          profession,
          birthCity,
          birthState,
          birthCountry,
          income: unmaskCurrency(income),
          patrimony,
          politicallyExposed,
        },
        address: {
          streetAddress,
          streetNumber,
          complement,
          neighborhood,
          zipcode: zipcode.replace(/-/g, ''),
          city,
          state,
        },
        bankAccount: {
          bankId,
          agencyNumber,
          accountNumber,
          accountType,
        },
      },
    })

    return response.status == HttpStatus.CREATED ? response.data : null
  }

  return { getCustomerInformation, createCustomer, updateCustomer }
}
