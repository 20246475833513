/* eslint-disable react/jsx-no-literals */
import React, { useContext } from 'react'
import { ApplicationContext } from 'context/Application'
import { FieldFormSimulationWrapper } from 'components/Wrappers/wrappers.style'
import { inputStyle } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'

import { FormSimulationTaxasSimpleStyle } from './form.style'

const FormSimulationSimpleRate = () => {
  const { state } = useContext(ApplicationContext)

  return (
    <FormSimulationTaxasSimpleStyle>
      <div>
        <FieldFormSimulationWrapper>
          <InputField
            variant="default"
            label="Taxa CET"
            css={inputStyle}
            type="text"
            value={`${state.cetRate.monthly}%`}
            disabled
          />
        </FieldFormSimulationWrapper>
      </div>
      <div>
        <FieldFormSimulationWrapper>
          <InputField
            variant="default"
            label="Taxa AP"
            css={inputStyle}
            type="text"
            value={`${state.apRate.monthly}%`}
            disabled
          />
        </FieldFormSimulationWrapper>
      </div>
      <div>
        <FieldFormSimulationWrapper>
          <InputField
            variant="default"
            label="IOF"
            css={inputStyle}
            type="text"
            value={`R$ ${state.iofAmount}`}
            disabled
          />
        </FieldFormSimulationWrapper>
      </div>
    </FormSimulationTaxasSimpleStyle>
  )
}

export { FormSimulationSimpleRate }
