import React from 'react'

import { Button } from '@creditas/button'
import { buttonSaveStyle } from './button.style'

// eslint-disable-next-line react/prop-types
const ButtonSave = ({ children, isLoading, disabled = false }) => (
  <Button
    isLoading={isLoading}
    disabled={disabled}
    fullWidth
    variant="tertiary"
    css={buttonSaveStyle}
  >
    {children}
  </Button>
)
export { ButtonSave }
