import React from 'react'
import { Navbar, NavbarGroup } from '@creditas/navbar'
import { LogoHorizontalPrincipal } from '@creditas/brand-icons'
import { ExitIcon } from 'components/Icons/ExitIcon'

function logoffFromHeader() {
  window.localStorage.removeItem('creditas-affiliates-state-token')
  window.localStorage.removeItem('creditas-affiliates-state-refresh-token')
}

const Header = () => (
  <Navbar
    data-testid="navbar"
    style={{
      background: 'linear-gradient(to right, #1cc 0%, #2e8 100%)',
      height: '50px',
      padding: '5px',
    }}
  >
    <NavbarGroup logo>
      <LogoHorizontalPrincipal
        variant="positive"
        style={{
          background: 'transparent',
          padding: '6px',
          height: '45px',
          display: 'inline-block',
        }}
      />
      <ExitIcon navigateTo="/" logoff={logoffFromHeader} />
    </NavbarGroup>
  </Navbar>
)

Header.displayName = 'Header'

export { Header }
