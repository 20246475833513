/* eslint-disable react/prop-types */

import React from 'react'

import { Wizard } from '@creditas/wizard'
import { WizardStyle } from './wizard.style'

const Steps = ({ current }) => (
  <WizardStyle>
    <Wizard steps={[1, 2, 3]} currentStep={current} />
  </WizardStyle>
)

export { Steps }
