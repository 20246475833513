import React from 'react'
import { any } from 'prop-types'
import MaskedInput from 'react-text-mask'
import { inputIndexStyle as css } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'

const propTypes = {
  mask: any.isRequired,
}

const BaseMaskedInputField = props => (
  <MaskedInput
    {...props}
    render={(ref, innerProps) => (
      <InputField css={css} ref={ref} {...innerProps} />
    )}
  />
)

BaseMaskedInputField.propTypes = propTypes

export { BaseMaskedInputField }
