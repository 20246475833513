/* eslint-disable react/jsx-no-literals */
import React, { useContext } from 'react'
import { ApplicationContext } from 'context/Application'
import { FieldFormSimulationWrapper } from 'components/Wrappers/wrappers.style'
import { inputStyle } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'
import { FormSimulationTaxasSimpleStyle } from './form.style'

const FormSimulationSimpleComparation = () => {
  const { state } = useContext(ApplicationContext)

  return (
    <FormSimulationTaxasSimpleStyle>
      <div>
        <FieldFormSimulationWrapper>
          <InputField
            variant="default"
            label="Empréstimo"
            css={inputStyle}
            type="text"
            value={`R$ ${state.loanAmountTotal}`}
            disabled
          />
        </FieldFormSimulationWrapper>
      </div>
      <div>
        <FieldFormSimulationWrapper>
          <InputField
            variant="default"
            label="Retirada"
            css={inputStyle}
            type="text"
            value={`R$ ${state.withdrawalAmountTotal}`}
            disabled
          />
        </FieldFormSimulationWrapper>
      </div>
      <div>
        <FieldFormSimulationWrapper>
          <InputField
            variant="default"
            label="Economia"
            css={inputStyle}
            type="text"
            value={`R$ ${state.customerSavingAmount}`}
            disabled
          />
        </FieldFormSimulationWrapper>
      </div>
    </FormSimulationTaxasSimpleStyle>
  )
}

export { FormSimulationSimpleComparation }
