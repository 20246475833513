/* eslint-disable react/jsx-no-literals */
import React, { useState } from 'react'
import { LogoHorizontalPrincipal } from '@creditas/brand-icons'
import { InputField } from '@creditas/form-elements'
import { ButtonSignOn } from 'components/Button/ButtonSignOn'
import {
  FormLoginStyle,
  FormLoginInputStyle,
  FormLoginButtonStyle,
  FormLoginLogoStyle,
  inputLoginIndexStyle,
} from './form.style'

// eslint-disable-next-line react/prop-types
const LoginForm = ({ login, showError, isLoading, hasAccessToken }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handlerSubmit = e => {
    e.preventDefault()
    login(username, password)
  }

  return hasAccessToken ? (
    (window.location = '/')
  ) : (
    <FormLoginStyle>
      <FormLoginLogoStyle>
        <LogoHorizontalPrincipal variant="positive" />
      </FormLoginLogoStyle>
      <form className="form-login" onSubmit={handlerSubmit}>
        <FormLoginInputStyle>
          <InputField
            css={inputLoginIndexStyle}
            className="form-login__input"
            required
            name="username"
            value={username}
            type="email"
            onChange={({ target }) => setUsername(target.value)}
            label="Email"
            placeholder="Email"
            disabled={isLoading}
          />
          <InputField
            css={inputLoginIndexStyle}
            className="form-login__input"
            required
            name="password"
            value={password}
            type="password"
            onChange={({ target }) => setPassword(target.value)}
            label="Password"
            placeholder="Password"
            disabled={isLoading}
          />
        </FormLoginInputStyle>
        <FormLoginButtonStyle>
          <ButtonSignOn
            type="submit"
            className="btn btn--primary"
            navigateTo="/"
            disabled={isLoading}
          >
            {isLoading ? (
              <i className="icon icon-spin2 animate-spin" />
            ) : (
              'Entrar'
            )}
          </ButtonSignOn>
        </FormLoginButtonStyle>
        {showError && (
          <div className="form-login__error">
            <p className="color-error">
              O endereço de email ou a senha que você inseriu não é válido.
            </p>
            <p>
              <span className="color-error">
                Tente novamente ou contacte o time de{' '}
              </span>
              <a href={process.env.REACT_APP_SERVICE_DESK} alt="Service Desk">
                SERVICE DESK
              </a>
              <span className="color-error">
                {' '}
                caso você não lembre sua senha.
              </span>
            </p>
          </div>
        )}
      </form>
    </FormLoginStyle>
  )
}

export { LoginForm }
