export const ampleSoft100 = {
  name: 'AmpleSoft Thin',
  url: 'assets/fonts/amplesoft/amplesoft_thin-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 100,
}

export const ampleSoft200 = {
  name: 'AmpleSoft Extra Light',
  url: 'assets/fonts/amplesoft/amplesoft_extralight-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 200,
}

export const ampleSoft300 = {
  name: 'AmpleSoft Light',
  url: 'assets/fonts/amplesoft/amplesoft_light-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 300,
}

export const ampleSoft400 = {
  name: 'AmpleSoft Regular',
  url: 'assets/fonts/amplesoft/amplesoft-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 400,
}

export const ampleSoft500 = {
  name: 'AmpleSoft Medium',
  url: 'assets/fonts/amplesoft/amplesoft_medium-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 500,
}

export const ampleSoft700 = {
  name: 'AmpleSoft Bold',
  url: 'assets/fonts/amplesoft/amplesoft_bold-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 700,
}

export const workSans300 = {
  name: 'Work Sans Light',
  url: 'assets/fonts/worksans/WorkSans-Light.subset.woff2',
  family: 'Work Sans',
  weight: 300,
}

export const workSans400 = {
  name: 'Work Sans Regular',
  url: 'assets/fonts/worksans/WorkSans-Regular.subset.woff2',
  family: 'Work Sans',
  weight: 400,
}

export const workSans500 = {
  name: 'Work Sans Medium',
  url: 'assets/fonts/worksans/WorkSans-Medium.subset.woff2',
  family: 'Work Sans',
  weight: 500,
}

export const workSans600 = {
  name: 'Work Sans Semi Bold',
  url: 'assets/fonts/worksans/WorkSans-SemiBold.subset.woff2',
  family: 'Work Sans',
  weight: 600,
}

export const workSans700 = {
  name: 'Work Sans Bold',
  url: 'assets/fonts/worksans/WorkSans-Bold.subset.woff2',
  family: 'Work Sans',
  weight: 700,
}

export const fonts = [
  ampleSoft100,
  workSans300,
  workSans400,
  workSans500,
  workSans600,
  workSans700,
]
