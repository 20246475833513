/* eslint-disable complexity */
/* eslint-disable react/jsx-no-literals */

import React from 'react'
import { node, func } from 'prop-types'
import { FieldFormWrapper } from 'components/Wrappers/wrappers.style'
import { DateInputField } from 'components/MaskedInputField'
import { inputStyle } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'
import { DetailsClientSelectField } from 'components/InputField'
import { mapTextValueOptionsBuilder } from 'utils/builders'
import { ACCEPTABLE_DOCUMENT_TYPES } from 'constants/customerInformation'
import { FormClientInfomationGeralStyle } from './form.style'

const propTypes = {
  values: node.isRequired,
  errors: node.isRequired,
  touched: node.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
}

const FormClientInfomationDocument = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  return (
    <FormClientInfomationGeralStyle>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="documentType"
          name="documentType"
          value={values.documentType}
          label="Tipo de documento"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={ACCEPTABLE_DOCUMENT_TYPES.map(
            mapTextValueOptionsBuilder({}),
          )}
          variant={
            touched.documentType && errors.documentType ? 'danger' : 'default'
          }
          helperText={touched.documentType && errors.documentType}
        />
      </FieldFormWrapper>
      {values.documentType === 'rg' && (
        <FieldFormWrapper>
          <InputField
            documentType="rg"
            id="rgNumber"
            name="rgNumber"
            value={values.rgNumber}
            defaultValue={values.rgNumber}
            label="RG"
            css={inputStyle}
            type="text"
            onChange={handleChange}
            disabled
            onBlur={handleBlur}
          />
        </FieldFormWrapper>
      )}
      {values.documentType !== 'rg' && (
        <FieldFormWrapper>
          <InputField
            id="documentNumber"
            name="documentNumber"
            value={values.documentNumber}
            label={values.documentType?.toUpperCase() || 'Documento'}
            css={inputStyle}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            variant={
              touched.documentNumber && errors.documentNumber
                ? 'danger'
                : 'default'
            }
            helperText={touched.documentNumber && errors.documentNumber}
          />
        </FieldFormWrapper>
      )}
      <FieldFormWrapper>
        <DateInputField
          id="documentIssuingDate"
          name="documentIssuingDate"
          value={values.documentIssuingDate}
          label={`Data de expedição do ${
            values.documentType?.toUpperCase() || 'Documento'
          }`}
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.documentIssuingDate && errors.documentIssuingDate
              ? 'danger'
              : 'default'
          }
          helperText={touched.documentIssuingDate && errors.documentIssuingDate}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="documentIssuingBody"
          name="documentIssuingBody"
          value={values.documentIssuingBody}
          label={`Órgão emissor do ${
            values.documentType?.toUpperCase() || 'Documento'
          }`}
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.documentIssuingBody && errors.documentIssuingBody
              ? 'danger'
              : 'default'
          }
          helperText={touched.documentIssuingBody && errors.documentIssuingBody}
        />
      </FieldFormWrapper>
    </FormClientInfomationGeralStyle>
  )
}

FormClientInfomationDocument.propTypes = propTypes

export { FormClientInfomationDocument }
