import React from 'react'
import { any, number } from 'prop-types'
import { currencyMask, defaultCurrencyMask } from 'masks/currency'
import createNumberMask from 'masks/createNumberMask'
import { BaseMaskedInputField } from './BaseMaskedInputField'

const propTypes = {
  valueLimit: number,
  integerLimit: number,
  props: any,
}

const defaultProps = {
  valueLimit: null,
  integerLimit: null,
}

const AmountInputField = ({ valueLimit, integerLimit, ...props }) => {
  const mask =
    valueLimit == null
      ? currencyMask
      : createNumberMask({
          ...defaultCurrencyMask,
          valueLimit,
        })

  return (
    <BaseMaskedInputField mask={mask} data-testid="amount-field" {...props} />
  )
}

AmountInputField.propTypes = propTypes

AmountInputField.defaultProps = defaultProps

export { AmountInputField }
