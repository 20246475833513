import * as yup from 'yup'
import {
  ACCEPTABLE_TERMS,
  MINIMUM_REQUESTABLE_LOAN_AMOUNT,
} from 'constants/simulation'
import { toCurrency } from 'utils/formatters'
import { unmaskCurrency } from 'utils/maskRemover'
import { REQUIRED_FIELD_MESSAGE } from './constants'

function useSimulationSchema(maximumRequestableLoanAmount) {
  const requestedLoanAmountSchema = yup
    .number()
    .transform((value, originalValue) => {
      const unmaskedValue = unmaskCurrency(originalValue)
      return parseFloat(unmaskedValue)
    })
    .min(
      MINIMUM_REQUESTABLE_LOAN_AMOUNT,
      ({ min }) => `Valor mínimo é de ${toCurrency({ number: min })}`,
    )
    .max(
      maximumRequestableLoanAmount,
      ({ max }) => `Valor máximo é R$ ${toCurrency({ number: max })}`,
    )
    .required(REQUIRED_FIELD_MESSAGE)

  const termSchema = yup
    .number()
    .oneOf(ACCEPTABLE_TERMS, 'Número de parcelas inválido')
    .required(REQUIRED_FIELD_MESSAGE)

  return yup.object().shape({
    requestedLoanAmount: requestedLoanAmountSchema,
    term: termSchema,
  })
}

export { useSimulationSchema }
