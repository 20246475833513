/* eslint-disable babel/camelcase */

import axios from 'axios'
import HttpStatus from 'http-status-codes'

export function useConsultant() {
  const axiosInstance = axios.create({
    baseURL: process.env.AUTH_DOMAIN_NAME,
    headers: {
      Accept: 'application/json',
      'Accept-Version': 'v1',
      'Content-Type': 'application/json',
    },
    validateStatus: false,
  })

  async function getAuthentication({ username = '', password = '' }) {
    const response = await axiosInstance.request({
      method: 'POST',
      url: `/tokens`,
      data: {
        username,
        password,
        grant_type: 'password',
      },
    })
    return response.status == HttpStatus.CREATED ? response.data : null
  }

  async function refreshTokenAuthentication(refreshToken) {
    const response = await axiosInstance.request({
      method: 'POST',
      url: `/tokens`,
      data: {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      },
    })
    return response.status == HttpStatus.CREATED ? response.data : null
  }

  async function showTokenInfo() {
    const response = await axiosInstance.request({
      method: 'GET',
      url: `/tokens/info`,
      headers: {
        Authorization: window.localStorage.getItem(
          'creditas-affiliates-state-token',
        ),
      },
    })
    return response.status == HttpStatus.OK ? response.data : null
  }

  function removeLocalStorageInfo() {
    window.localStorage.removeItem('creditas-affiliates-state-token')
    window.localStorage.removeItem('creditas-affiliates-state-refresh-token')
  }

  function setLocalStorageInfo(tokenRequest) {
    window.localStorage.setItem(
      'creditas-affiliates-state-token',
      `Bearer ${tokenRequest.access_token}`,
    )
    window.localStorage.setItem(
      'creditas-affiliates-state-refresh-token',
      tokenRequest.refresh_token,
    )
  }

  return {
    getAuthentication,
    showTokenInfo,
    refreshTokenAuthentication,
    removeLocalStorageInfo,
    setLocalStorageInfo,
  }
}
