import React from 'react'
import { node } from 'prop-types'
import { cellphoneMask } from 'masks/cellphone'
import { BaseMaskedInputField } from './BaseMaskedInputField'

const propTypes = {
  props: node,
}

const CellPhoneInputField = ({ ...props }) => (
  <BaseMaskedInputField
    mask={cellphoneMask}
    data-testid="cellphone-field"
    {...props}
  />
)

CellPhoneInputField.propTypes = propTypes

export { CellPhoneInputField }
