import createNumberMask from './createNumberMask'

export const defaultCurrencyMask = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  requireDecimal: true,
  allowDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: false,
}

export const currencyMask = createNumberMask({
  ...defaultCurrencyMask,
  integerLimit: 5,
})
