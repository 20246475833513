/* eslint-disable react/prop-types */
import React from 'react'

import { InputSelect } from '@creditas/form-elements'
import { inputSelectStyle } from './inputSelect.style'

const DetailsClientSelectField = ({
  disabled = false,
  id,
  name,
  value,
  label,
  type,
  onChange,
  onBlur,
  variant,
  helperText,
  options,
}) => {
  return (
    <InputSelect
      disabled={disabled}
      id={id}
      name={name}
      value={value}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      variant={variant}
      helperText={helperText}
      options={options}
      size="comfortable"
      css={inputSelectStyle}
    />
  )
}

export { DetailsClientSelectField }
