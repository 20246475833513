import React from 'react'
import { oneOf, node } from 'prop-types'
import { documentMasks } from 'masks/document'
import { BaseMaskedInputField } from './BaseMaskedInputField'

const propTypes = {
  documentType: oneOf(Object.keys(documentMasks)),
  props: node,
}

const DocumentInputField = ({
  documentType = Object.keys(documentMasks)[0],
  ...props
}) => (
  <BaseMaskedInputField
    mask={documentMasks[documentType]}
    data-testid="document-field"
    {...props}
  />
)

DocumentInputField.propTypes = propTypes

export { DocumentInputField }
