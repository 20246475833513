/* eslint-disable complexity */
/* eslint-disable react/jsx-no-literals */

import React, { useState, useEffect } from 'react'
import { node, func } from 'prop-types'
import { FieldFormWrapper } from 'components/Wrappers/wrappers.style'
import {
  AmountInputField,
  CellPhoneInputField,
  DocumentInputField,
  DateInputField,
} from 'components/MaskedInputField'
import { inputStyle } from 'components/InputField/inputSelect.style'
import { InputField } from '@creditas/form-elements'
import { DetailsClientSelectField } from 'components/InputField'
import { toSlashDate } from 'utils/formatters'
import {
  mapDefaultOptionsBuilder,
  mapTextValueOptionsBuilder,
} from 'utils/builders'
import {
  ACCEPTABLE_MARITAL_STATUSES,
  ACCEPTABLE_PATRIMONY_RANGES,
  ACCEPTABLE_PEP,
} from 'constants/customerInformation'
import { useLocale } from 'hooks/useLocale'
import { FormClientInfomationGeralStyle } from './form.style'

const propTypes = {
  values: node.isRequired,
  errors: node.isRequired,
  touched: node.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
}

const FormClientInfomationGeral = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  const { getCities, getStates } = useLocale()
  const [states, setStates] = useState([])
  const [citiesByState, setCitiesByState] = useState({})

  useEffect(() => {
    getStates().then(data => {
      const sortedStates = data
        .sort((a, b) => (a.sigla > b.sigla ? 1 : -1))
        .map(({ sigla }) => sigla)

      setStates(() => sortedStates)

      Promise.all(sortedStates.map(state => getCities({ state }))).then(
        citiesResponse => {
          setCitiesByState(() =>
            sortedStates.reduce((acc, state, index) => {
              acc[state] = citiesResponse[index].map(({ nome }) => nome)
              return acc
            }, {}),
          )
        },
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormClientInfomationGeralStyle>
      <FieldFormWrapper>
        <InputField
          disabled
          id="fullname"
          name="fullname"
          value={values.fullname}
          label="Nome"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={touched.fullname && errors.fullname ? 'danger' : 'default'}
          helperText={touched.fullname && errors.fullname}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DocumentInputField
          documentType="cpf"
          disabled
          id="document"
          name="document"
          value={values.document}
          label="CPF"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={touched.document && errors.document ? 'danger' : 'default'}
          helperText={touched.document && errors.document}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DateInputField
          disabled
          id="birthDate"
          name="birthDate"
          value={toSlashDate({ date: values.birthDate })}
          label="Data de Nascimento"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={touched.birthDate && errors.birthDate ? 'danger' : 'default'}
          helperText={touched.birthDate && errors.birthDate}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="email"
          name="email"
          value={values.email}
          label="Email"
          css={inputStyle}
          type="email"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={touched.email && errors.email ? 'danger' : 'default'}
          helperText={touched.email && errors.email}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <CellPhoneInputField
          id="phoneNumber"
          name="phoneNumber"
          value={values.phoneNumber}
          label="Celular"
          css={inputStyle}
          type="tel"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            (touched.phoneNumber || values.phoneNumber) && errors.phoneNumber
              ? 'danger'
              : 'default'
          }
          helperText={
            (touched.phoneNumber || values.phoneNumber) && errors.phoneNumber
          }
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="maritalStatus"
          name="maritalStatus"
          value={values.maritalStatus}
          label="Estado Civil"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={ACCEPTABLE_MARITAL_STATUSES.map(
            mapTextValueOptionsBuilder(),
          )}
          variant={
            touched.maritalStatus && errors.maritalStatus ? 'danger' : 'default'
          }
          helperText={touched.maritalStatus && errors.maritalStatus}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          documentType="rg"
          id="rgNumber"
          name="rgNumber"
          value={values.rgNumber}
          label="RG"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={touched.rgNumber && errors.rgNumber ? 'danger' : 'default'}
          helperText={touched.rgNumber && errors.rgNumber}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="motherName"
          name="motherName"
          value={values.motherName}
          label="Nome da Mãe"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.motherName && errors.motherName ? 'danger' : 'default'
          }
          helperText={touched.motherName && errors.motherName}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          id="profession"
          name="profession"
          value={values.profession}
          label="Profissão"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.profession && errors.profession ? 'danger' : 'default'
          }
          helperText={touched.profession && errors.profession}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <InputField
          disabled
          id="birthCountry"
          name="birthCountry"
          value={values.birthCountry}
          label="País de nascimento"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={
            touched.birthCountry && errors.birthCountry ? 'danger' : 'default'
          }
          helperText={touched.birthCountry && errors.birthCountry}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="birthState"
          name="birthState"
          value={values.birthState}
          label="Estado de nascimento"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={states.map(mapDefaultOptionsBuilder({}))}
          variant={
            touched.birthState && errors.birthState ? 'danger' : 'default'
          }
          helperText={touched.birthState && errors.birthState}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="birthCity"
          name="birthCity"
          value={values.birthCity}
          label="Cidade de nascimento"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={
            citiesByState[values.birthState] == null
              ? []
              : citiesByState[values.birthState].map(
                  mapDefaultOptionsBuilder({}),
                )
          }
          variant={touched.birthCity && errors.birthCity ? 'danger' : 'default'}
          helperText={touched.birthCity && errors.birthCity}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <AmountInputField
          valueLimit={250000}
          id="income"
          name="income"
          value={values.income}
          label="Renda"
          css={inputStyle}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          variant={touched.income && errors.income ? 'danger' : 'default'}
          helperText={touched.income && errors.income}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="patrimony"
          name="patrimony"
          value={values.patrimony}
          label="Patrimônio"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={ACCEPTABLE_PATRIMONY_RANGES.map(
            mapTextValueOptionsBuilder(),
          )}
          variant={touched.patrimony && errors.patrimony ? 'danger' : 'default'}
          helperText={touched.patrimony && errors.patrimony}
        />
      </FieldFormWrapper>
      <FieldFormWrapper>
        <DetailsClientSelectField
          id="politicallyExposed"
          name="politicallyExposed"
          value={values.politicallyExposed}
          label="Politicamente Exposta"
          type="select"
          onChange={handleChange}
          onBlur={handleBlur}
          options={ACCEPTABLE_PEP.map(mapTextValueOptionsBuilder())}
          variant={
            touched.politicallyExposed && errors.politicallyExposed
              ? 'danger'
              : 'default'
          }
          helperText={touched.politicallyExposed && errors.politicallyExposed}
        />
      </FieldFormWrapper>
    </FormClientInfomationGeralStyle>
  )
}

FormClientInfomationGeral.propTypes = propTypes

export { FormClientInfomationGeral }
