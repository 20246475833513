import { css } from '@creditas/stylitas'

export const inputStyle = css`
  border: none;
  margin: 1px;
  border-radius: 0;
  border-bottom: 1px solid;
  padding: 10px 10px;
  color: rgba(74, 74, 74, 0.54);
  position: relative;
  background-position: 98% 50%;
  background-size: contain;
  margin-top: -0.0625em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  text-align: left;
  min-width: 1%;
  width: 100%;
  outline: none;

  :hover {
    border: none;
    border-bottom: 1px solid #2e8;
  }

  :focus-within {
    border: none;
    border-bottom: 1px solid #2e8;
    color: rgba(74, 74, 74, 0.54);
    transform-origin: 0 0;
    text-overflow: ellipsis;
  }
`

export const inputIndexStyle = css`
  border: none;
  top: 100%;
  left: 30%;
  border-radius: 0;
  border-bottom: 1px solid;
  padding: 10px 10px;
  color: rgba(74, 74, 74, 0.54);
  position: relative;
  background-position: 98% 50%;
  background-size: contain;
  margin-top: -0.0625em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  text-align: left;
  min-width: 1%;
  width: 100%;
  outline: none;

  :hover {
    border: none;
    border-bottom: 1px solid #2e8;
  }

  :focus-within {
    border: none;
    border-bottom: 1px solid #2e8;
    color: rgba(74, 74, 74, 0.54);
    transform-origin: 0 0;
    text-overflow: ellipsis;
  }
`

export const inputSelectStyle = css`
  border: none;
  margin: 1px;
  border-radius: 0;
  border-bottom: 1px solid;
  color: rgba(74, 74, 74, 0.54);
  position: relative;
  padding: 4px 4px;
  background-position: 98% 50%;
  background-size: contain;
  margin-top: -0.0625em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  text-align: left;
  min-width: 1%;
  width: 100%;
  top: 5px;
  cursor: pointer;

  :hover {
    border: none;
    border-bottom: 1px solid;
  }

  :focus-within {
    border: none;
    border-bottom: 1px solid #2e8;
    color: rgba(74, 74, 74, 0.54);
    transform-origin: 0 0;
    text-overflow: ellipsis;
  }
`
