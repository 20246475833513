export const ACCEPTABLE_MARITAL_STATUSES = [
  ['SOLTEIRO', 'Solteiro'],
  ['CASADO', 'Casado'],
  ['DIVORCIADO', 'Divorciado'],
  ['VIUVO', 'Viúvo'],
  ['SEPARADO', 'Separado'],
  ['UNIAO_ESTAVEL', 'União estável'],
  ['NAO_INFORMADO', 'Não informado'],
]

export const ACCEPTABLE_PATRIMONY_RANGES = [
  ['UP_TO_30_THOUSAND', 'Até 30 mil reais'],
  ['BETWEEN_30_THOUSAND_AND_50_THOUSAND', 'De 30 mil a 50 mil reais'],
  ['BETWEEN_50_THOUSAND_AND_70_THOUSAND', 'De 50 mil a 70 mil reais'],
  ['BETWEEN_70_THOUSAND_AND_90_THOUSAND', 'De 70 mil a 90 mil reais'],
  ['BETWEEN_90_THOUSAND_AND_150_THOUSAND', 'De 90 mil a 150 mil reais'],
  ['BETWEEN_150_THOUSAND_AND_200_THOUSAND', 'De 150 mil a 200 mil reais'],
  ['BETWEEN_200_THOUSAND_AND_300_THOUSAND', 'De 200 mil a 300 mil reais'],
  ['BETWEEN_300_THOUSAND_AND_500_THOUSAND', 'De 300 mil a 500 mil reais'],
  ['BETWEEN_500_THOUSAND_AND_700_THOUSAND', 'De 500 mil a 700 mil reais'],
  ['BETWEEN_700_THOUSAND_AND_1_MILLION', 'De 700 mil a 1 milhão de reais'],
  ['ABOVE_1_MILLION', 'Acima de 1 milhão de reais'],
]

export const ACCEPTABLE_BANKS = [
  ['1', 'Banco do Brasil', true],
  ['104', 'Caixa Economica Federal', true],
  ['33', 'Santander (Brasil)', true],
  ['237', 'Bradesco', true],
  ['341', 'Itaú Unibanco', true],
  ['41', 'Banco do Estado do Rio Grande do Sul'],
  ['70', 'BRB - Banco de Brasilia'],
  ['184', 'Itaú BBA'],
  ['756', 'SICOOB / BANCOOB - Banco Cooperativo do Brasil S.A.'],
  ['748', 'Banco Cooperativo Sicredi'],
  ['745', 'Citibank'],
  ['473', 'Caixa Geral - Brasil'],
  ['37', 'Banco do Estado do Pará'],
  ['204', 'Bradesco Cartões'],
  ['652', 'Itaú Unibanco Holding'],
  ['479', 'Itaubank'],
  ['21', 'BANESTES Banco do Estado do Espirito Santo'],
  ['4', 'Banco do Nordeste do Brasil'],
  ['389', 'Banco Mercantil do Brasil'],
  ['394', 'Bradesco Financiamentos'],
  ['36', 'Bradesco BBI'],
  ['47', 'Banco do Estado de Sergipe'],
  ['168', 'HSBC Finance (Brasil) - Banco Múltiplo'],
  ['29', 'Itaú BMG Consignado'],
  ['122', 'Bradesco BERJ'],
  ['422', 'Safra'],
  ['3', 'Banco Da Amazonia'],
  ['212', 'Original'],
  ['477', 'CITIBANK N.A.'],
  ['409', 'Unibanco - União de Bancos Brasileiros'],
  ['246', 'ABC Brasil'],
  ['208', 'BTG Pactual'],
  ['132', 'ICBC do Brasil Banco Múltiplo'],
  ['735', 'Banco Neon (Pottencial)'],
  ['77', 'Intermedium'],
  ['487', 'Deutsche Bank - Banco Alemao'],
  ['69', 'BPN Brasil Banco Múltiplo'],
  ['655', 'Votorantim'],
  ['107', 'BBM'],
  ['741', 'Banco Ribeirao Preto'],
  ['66', 'Morgan Stanley Dean Witter'],
  ['366', 'Societe Generale Brasil'],
  ['320', 'Banco Industrial e Comercial'],
  ['318', 'Banco BMG', true],
  ['124', 'Woori Bank do Brasil'],
  ['217', 'John Deere'],
  ['399', 'HSBC Bank Brasil'],
  ['356', 'ABN AMRO'],
  ['505', 'Credit Suisse (Brasil)'],
  ['M24', 'PSA Finance Brasil'],
  ['254', 'Paraná Banco'],
  ['125', 'Brasil Plural Banco Múltiplo'],
  ['623', 'Banco Panamericano'],
  ['739', 'BGN'],
  ['743', 'Semear'],
  ['96', 'BM&FBOVESPA de Serviços de Liquidação e Custódia'],
  ['747', 'Rabobank International Brasil'],
  ['752', 'BNP Paribas Brasil'],
  ['757', 'KEB do Brasil'],
  ['M19', 'CNH Capital'],
  ['62', 'Hipercard Múltiplo'],
  ['74', 'J. Safra'],
  ['25', 'Alfa'],
  ['40', 'Banco Cargill'],
  ['64', 'Goldman Sachs do Brasil Banco Multiplo'],
  ['M06', 'Banco de Lage Landen Brasil'],
  ['M10', 'Moneo'],
  ['76', 'KDB do Brasil'],
  ['82', 'Banco Topázio'],
  ['14', 'Natixis Brasil Banco Múltiplo'],
  ['19', 'Banco Azteca do Brasil'],
  ['79', 'Banco Original do Agronegócio'],
  ['121', 'Banco Gerador'],
  ['83', 'Banco Da China Brasil'],
  ['24', 'Banco de Pernambuco - BANDEPE'],
  ['88', 'Randon'],
  ['95', 'Confidence de Câmbio'],
  ['94', 'Petra'],
  ['119', 'Western Union do Brasil'],
  ['412', 'Banco Capital'],
  ['634', 'Triangulo'],
  ['915', 'BRJ'],
  ['751', 'Scotiabank Brasil Banco Múltiplo'],
  ['746', 'Modal'],
  ['241', 'Classico'],
  ['612', 'Guanabara'],
  ['604', 'Banco Industrial do Brasil'],
  ['300', 'Banco de La Nacion Argentina'],
  ['266', 'Cedula'],
  ['376', 'J.P. Morgan'],
  ['263', 'Banco Cacique'],
  ['248', 'Boavista Interatlantico'],
  ['120', 'Rodobens'],
  ['265', 'Fator'],
  ['641', 'Alvorada'],
  ['719', 'BANIF - Banco Internacional do Funchal (Brasil)'],
  ['243', 'Banco Máxima'],
  ['M08', 'Citicard'],
  ['M11', 'IBM'],
  ['495', 'Banco de La Provincia de Buenos Aires'],
  ['488', 'JPMORGAN Chase Bank, National Association'],
  ['65', 'Banco Bracce'],
  ['492', 'ING Bank N.V.'],
  ['250', 'BCV - Banco de Crédito e Varejo'],
  ['494', 'Banco de La Republica Oriental Del Uruguay'],
  ['213', 'ARBI'],
  ['18', 'Tricury'],
  ['M23', 'Banco Volvo Brasil'],
  ['630', 'Intercap'],
  ['224', 'Banco Fibra'],
  ['M14', 'Banco Volkswagen'],
  ['600', 'Banco Luso Brasileiro'],
  ['M07', 'GMAC'],
  ['0', 'BANKPAR'],
  ['456', 'Banco de Tokyo-Mitsubishi UFJ Brasil'],
  ['464', 'Sumitomo Mitsui Brasileiro'],
  ['613', 'Banco Pecunia'],
  ['637', 'Sofisa'],
  ['653', 'Banco Indusval'],
  ['230', 'Unicard Banco Múltiplo'],
  ['370', 'Mizuho do Brasil'],
  ['740', 'Barclays'],
  ['249', 'Investcred Unibanco'],
  ['214', 'Dibens'],
  ['626', 'FICSA'],
  ['611', 'Banco Paulista'],
  ['755', 'Bank oF America Merrill Lynch Banco Múltiplo'],
  ['643', 'Pine'],
  ['707', 'Daycoval'],
  ['233', 'Banco Cifra'],
  ['633', 'Rendimento'],
  ['218', 'Banco Bonsucesso'],
  ['222', 'Crédit Agricole Brasil'],
  ['610', 'Banco VR'],
  ['972', 'Ourinvest'],
  ['720', 'Maxinvest'],
  ['M18', 'Banco Ford'],
  ['654', 'A.J. Renner'],
  ['85', 'Cooperativa Central de Crédito Urbano-CECRED'],
  ['260', 'Nubank Pagamentos S.A', true],
  ['084', 'Uniprime - Cooperativa de Crédito do Norte do Paraná'],
  ['136', 'Unicred do Brasil'],
  ['290', 'PagSeguro Internet S.A.'],
  ['336', 'Banco C6 S.A.'],
  ['133', 'Cresol Confederação'],
  ['323', 'Mercado Pago'],
]

export const ACCEPTABLE_ACCOUNT_TYPES = [
  ['CORRENTE', 'Conta corrente'],
  ['POUPANÇA', 'Conta poupança'],
]

export const ACCEPTABLE_PEP = [
  [true, 'Sim'],
  [false, 'Não'],
]

export const ACCEPTABLE_DOCUMENT_TYPES = [
  ['cnh', 'CNH'],
  ['rg', 'RG'],
]
