import React from 'react'
import { node } from 'prop-types'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import { ToasterProvider } from '@creditas/toaster'
import { Container } from 'components/Container'
import { Global } from 'components/Global'
import { Header } from 'components/Header'
import { Footer } from 'components/Footer'
import { ImportFonts } from 'components/ImportFonts'
import { toPrefixUrl } from 'utils/toPrefixUrl'
import { fonts } from 'constants/fonts'
import i18n from 'src/i18n'

const propTypes = {
  children: node.isRequired,
}

const containerStyle = {
  marginLeft: '0',
  marginRight: '0',
}

const Layout = ({ children }) => (
  <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={{ ...tokens }}>
      <ImportFonts fonts={fonts.map(toPrefixUrl)} />
      <Global />
      <Header />
      <ToasterProvider>
        <Container style={containerStyle}>{children}</Container>
      </ToasterProvider>
      <Footer sticky />
    </ThemeProvider>
  </I18nextProvider>
)

Layout.propTypes = propTypes
Layout.displayName = 'Layout'

export { Layout }
