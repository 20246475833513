import React from 'react'
import { node } from 'prop-types'
import Login from 'src/pages/Login'

const propTypes = {
  children: node.isRequired,
}

function Auth({ children }) {
  let stateToken
  if (typeof window !== `undefined`) {
    stateToken = window.localStorage.getItem('creditas-affiliates-state-token')
  }

  function isAuthenticated() {
    return stateToken
  }

  return isAuthenticated() ? <span>{children}</span> : <Login />
}

Auth.propTypes = propTypes

export { Auth }
