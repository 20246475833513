import React from 'react'
import { navigate } from 'gatsby'
import { string, boolean } from 'prop-types'

import { Button } from '@creditas/button'
import { ButtonSaveProposalStyle } from './button.style'

// eslint-disable-next-line react/prop-types
const propTypes = {
  navigateTo: string.isRequired,
  children: string,
  disabled: boolean,
}

const ButtonSaveProposal = ({ children, navigateTo, disabled }) => (
  <Button
    fullWidth
    variant="tertiary"
    disabled={disabled}
    onClick={() => navigate(navigateTo)}
    css={ButtonSaveProposalStyle}
  >
    {children}
  </Button>
)

export { ButtonSaveProposal }
ButtonSaveProposal.propTypes = propTypes
