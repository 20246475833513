import * as yup from 'yup'

import { unmaskCellphone } from 'src/utils/maskRemover'
import { REQUIRED_FIELD_MESSAGE } from './constants'

const TODAY = new Date()

const accountNumberSchema = yup
  .string()
  .matches(/^[0-9]+(-[0-9xX])?$/i, 'Conta inválida')
  .min(4, 'Conta inválida')
  .max(15, 'Conta inválida')
  .required(REQUIRED_FIELD_MESSAGE)

const agencyNumberSchema = yup
  .string()
  .matches(/^[0-9]+(-[0-9xX])?$/i, 'Agência inválida')
  .min(2, 'Agência inválida')
  .max(6, 'Agência inválida')
  .required(REQUIRED_FIELD_MESSAGE)

const dateSchema = yup
  .date()
  .typeError('Data inválida.')
  .transform((value, originalValue) => {
    const [day, month, year] =
      originalValue instanceof Date
        ? [
            originalValue.getDate(),
            originalValue.getMonth(),
            originalValue.getFullYear(),
          ]
        : originalValue.split('/')

    return new Date(year, month, day)
  })
  .max(
    TODAY,
    `Data máxima aceita é ${TODAY.getDate()}/${
      TODAY.getMonth() + 1
    }/${TODAY.getFullYear()}`,
  )
  .required(REQUIRED_FIELD_MESSAGE)

const documentNumberSchema = yup.string().when('documentType', {
  is: 'cnh',
  then: yup.string().matches(/\d{11}/i, 'CNH Inválida.'),
})

const emailSchema = yup
  .string()
  .email('Email inválido.')
  .required(REQUIRED_FIELD_MESSAGE)

const numberSchema = yup
  .string()
  .matches(/^[0-9]+$/i, 'Apenas números são aceitos.')
  .required(REQUIRED_FIELD_MESSAGE)

const phoneNumberSchema = yup
  .string()
  .transform((value, originalValue) => unmaskCellphone(originalValue))
  .length(11, 'Celular inválido')
  .required(REQUIRED_FIELD_MESSAGE)

const rgNumberSchema = yup
  .string()
  .matches(
    /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-.]+$/i,
    'Apenas letras e números são aceitos.',
  )
  .required(REQUIRED_FIELD_MESSAGE)

const textAndNumberSchema = yup
  .string()
  .matches(
    /^[0-9A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\-. ]+$/i,
    'Apenas letras e números são aceitos.',
  )

const textRequired = yup.string().required(REQUIRED_FIELD_MESSAGE)

const textSchema = yup
  .string()
  .matches(
    /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/i,
    'Apenas letras são aceitas.',
  )
  .required(REQUIRED_FIELD_MESSAGE)

const zipcodeSchema = yup
  .string()
  .matches(/\d{5}-\d{3}/i, 'CEP inválido.')
  .required(REQUIRED_FIELD_MESSAGE)

const customerInformationSchema = yup.object().shape({
  accountNumber: accountNumberSchema,
  agencyNumber: agencyNumberSchema,
  bankId: textRequired,
  birthCity: textRequired,
  birthState: textRequired,
  city: textRequired,
  complement: textAndNumberSchema.nullable(),
  documentIssuingBody: textSchema,
  documentIssuingDate: dateSchema,
  documentNumber: documentNumberSchema,
  documentType: textRequired,
  email: emailSchema,
  income: textRequired,
  neighborhood: textAndNumberSchema.required(REQUIRED_FIELD_MESSAGE),
  maritalStatus: textRequired,
  motherName: textSchema,
  patrimony: textRequired,
  phoneNumber: phoneNumberSchema,
  politicallyExposed: textRequired,
  profession: textSchema,
  rgNumber: rgNumberSchema,
  state: textRequired,
  streetAddress: textAndNumberSchema.required(REQUIRED_FIELD_MESSAGE),
  streetNumber: numberSchema,
  zipcode: zipcodeSchema,
})

export { customerInformationSchema }
