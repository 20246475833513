export function unmaskCurrency(value) {
  return value.match(/((\d*\.)+(\d*,\d*))/g) == null
    ? 0
    : value
        .replace(/[a-zA-z!@#$%^&* ]/g, '')
        .replace(/\./g, '')
        .replace(',', '.')
        .match(/\d*\.\d*/g)[0]
}

export function unmaskZipcode(value) {
  return value.match(/\d/g) == null ? value : value.replace(/-/g, '')
}

export function unmaskYear(value) {
  const numbers = value.match(/\d*/g)
  return numbers == null ? 0 : numbers.join('')
}

export function unmaskCpf(value) {
  return value.replace(/\.|-/g, '')
}

export function unmaskRg(value) {
  return value.replace(/\.|-/g, '')
}

export function unmaskCnh(value) {
  return value.replace(/\.|-/g, '')
}

export function unmaskCellphone(value) {
  return value.replace(/\s+|[(_)]|\.|-/g, '')
}
