import { styled } from '@creditas/stylitas'

export const H2Style = styled.div`
  padding-left: 17px;
  font-size: 1.7em;
  color: #2f2f2f;
  font: 400 32px/40px Amplesoft, sans-serif;
  display: inline-flex;
`

export const H2IndexStyle = styled.div`
  padding-left: 17px;
  margin-top: 1%;
  font-size: 1.7em;
  color: #2f2f2f;
  font: 400 32px/40px Amplesoft, sans-serif;
  display: inline-flex;
`

export const H3Style = styled.div`
  padding: 5px;
  color: rgba(74, 74, 74, 0.54);
  background-color: white;
  display: inline-flex;
  position: relative;
  border-radius: 4;
  border-bottom: 2px solid #2e8;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;
`
// font: 500 15px/20px Amplesoft, sans-serif;

export const HStepsStyle = styled.div`
  color: rgba(74, 74, 74, 0.54);
  background-color: transparent;
  width: 10%;
  height: 48px;
  min-width: 1px;
  justify-content: center;
  line-height: 36px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  margin: 0;
  top: 10%;
  left: 93%;
  padding: 0 24px;
  white-space: nowrap;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  border-bottom: 2px solid #2e8;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1) box-shadow 280ms
    cubic-bezier(0.4, 0, 0.2, 1);
`

export const H3HeaderStyle = styled.div`
  padding: 1px;
  bottom: 6px;
  color: white;
  left: 10px;
  background-color: transparent;
  display: inline-flex;
  position: relative;
  border-radius: 4;
  border-bottom: 2px solid #2e8;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 550;
  text-transform: uppercase;
`
export const H3TableHeadStyle = styled.div`
  color: rgba(74, 74, 74, 0.54);
  text-align: left;
  font-family: 'Work Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;
  padding-bottom: 5px;
`
