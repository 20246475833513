import React from 'react'
import { Layout } from 'components/Layout'
import { Container } from '@creditas/layout'
import { FormIndex } from 'components/Form/FormIndex'
import { Auth } from 'components/Auth'

const Onboarding = () => {
  return (
    <Auth>
      <Layout>
        <Container>
          <FormIndex />
        </Container>
      </Layout>
    </Auth>
  )
}

export default Onboarding
