import axios from 'axios'
import HttpStatus from 'http-status-codes'

export function useLocale() {
  const axiosInstance = axios.create({
    baseURL: process.env.LOCALE_GATEWAY,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: false,
  })

  async function getStates() {
    const response = await axiosInstance.request({
      method: 'GET',
      url: `/estados`,
    })

    return response.status == HttpStatus.OK ? response.data : null
  }

  async function getCities({ state = 'SP' }) {
    const response = await axiosInstance.request({
      method: 'GET',
      url: `/estados/${state}/municipios`,
    })

    return response.status == HttpStatus.OK ? response.data : null
  }

  return { getStates, getCities }
}
