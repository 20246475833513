import React from 'react'
import { node } from 'prop-types'
import { zipcodeMask } from 'masks/zipcode'
import { BaseMaskedInputField } from './BaseMaskedInputField'

const propTypes = {
  props: node,
}

const ZipcodeInputField = ({ ...props }) => (
  <BaseMaskedInputField
    mask={zipcodeMask}
    data-testid="zipcode-field"
    {...props}
  />
)

ZipcodeInputField.propTypes = propTypes

export { ZipcodeInputField }
